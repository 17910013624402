import { useRouter } from "next/router";
import React from "react";
import { Bids, Hero } from "../../components/component";
import Meta from "../../components/Meta";

const Home_1 = () => {
  const router = useRouter();
  return (
    <main>
      <Meta title="FlashPrompt" />
      <Hero />
      <Bids />
      {/* <Top_collection /> */}
      {/* <Tranding_category /> */}
      {/* <NewseLatter /> */}
    </main>
  );
};

export default Home_1;
